///
// Toggle
///
.c-toggle {
    position: absolute;
    top: 12px;
    right: 15px;
    z-index: z('god');
    width: 32px;
    height: 26px;
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    outline: none;

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    &:before {
        content: 'Menu';
        position: relative;
        top: neg(4px);
        left: neg(70px);
        font-size: rem(22px);
        font-weight: 600;
        color: color('red');
        text-transform: uppercase;
    }
}

///
// Icon
///
.c-toggle__icon {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background-color: color('red');
    transition: transform 0.3s;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 4px;
        margin-left: auto;
        margin-right: auto;
        background-color: inherit;
        transition: transform 0.3s ease-in-out;
    }

    &:after {
        top: 8px;
    }

    &:before {
        bottom: 8px;
    }

    .is-open & {
        background-color: transparent;

        &:after,
        &:before {
            top: 0;
            background-color: color('red');
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:before {
            transform: rotate(45deg);
        }
    }
}

/*

///
// Dropdown
///
.c-toggle-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    // Medium Max Desktops
    @include media-max(md) {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
    }

    // Medium Desktops
    @include media(md) {
        //right: neg(15px);
        //line-height: 1;
        margin-left: rem(10px);
    }

    &:after {
        content: '\f107';
        font-size: rem(16px);
        font-family: 'FontAwesome';
        color: color('green', 'medium');

        .is-expanded & {
            content: '\f106';
        }
    }
}

*/
