///
// Button
///
.c-btn {
    display: inline-block;
    padding: rem(10px) rem(halve($base-gutter));
    font-size: rem(18px);
    font-weight: 700;
    letter-spacing: 1px;
    border: 1px solid transparent;
    border-radius: 26px;
    text-decoration: none;
    text-transform: uppercase;
}

///
// Red
///
.c-btn--red {
    color: color('red');
    border-color: color('red');

    @include link-states() {
        color: white;
        background-color: color('red');
    }
}
