.c-logo {
    max-width: 360px;

    // Medium <=
    @include media-max(md) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    // Medium Desktops
    @include media(md) {
        display: inline-block;
        max-width: 340px;
    }

    // Large Desktops
    @include media(lg) {
        max-width: 405px;
    }

    .c-footer & {
        margin-bottom: rem(30px);
    }
}
