///
// Primary
///
.c-nav--primary {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
        padding-top: rem(20px);
        padding-bottom: rem(20px);
    }

    // Medium Desktops
    @include media(md) {
        //margin-right: rem(double($base-gutter));
    }

    .is-open & {
        // Medium Max Desktops
        @include media-max(md) {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            z-index: z('demigod');
            width: 100%;
            height: 100%;
            padding: 60px 30px 30px;
            background-color: white;
        }
    }
}

///
// Secondary
///
.c-nav--secondary {
    // Medium <=
    @include media-max(md) {
        display: none;
    }

    // Medium Desktops
    @include media(md) {
        margin-top: rem(30px);
    }
}

///
// List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
}

///
// Item
///
.c-nav__item {
    .c-nav--primary &,
    .c-nav--secondary & {
        // Medium Desktops
        @include media(md) {
            display: inline-block;
            vertical-align: top;
            //transition: all 0.5s ease-in-out;
            //transform: translateY(-6vh);
            //opacity: 0;

            .is-visible & {
                //transform: translateY(0);
                //opacity: 1;
            }
        }

        + .c-nav__item {
            // Medium Max Desktops
            @include media-max(md) {
                margin-top: rem(halve($base-gutter));
            }

            // Medium Desktops
            @include media(md) {
                margin-left: rem(halve($base-gutter));
            }
        }
    }

    a {
        font-weight: 700;
        letter-spacing: 1px;
        color: color('grey', 'darkest');
        text-decoration: none;
        text-transform: uppercase;

        // Medium Desktops
        @include media(md) {
            font-size: rem(13px);
        }

        // Large Desktops
        @include media(lg) {
            font-size: rem(16px);
        }
    }
}

.c-nav__item + .c-nav__item {
    // Medium <=
    @include media-max(md) {
        padding-top: rem(halve($base-gutter));
        border-top: 1px solid color('red');
    }
}

///
// Home
///
.menu-home {
    // Medium Desktops
    @include media(md) {
        display: none !important;
    }
}

///
// Contact
///
.menu-contact {
    // Medium <=
    @include media-max(md) {
        padding-bottom: rem(halve($base-gutter));
        border-bottom: 1px solid color('red');
    }

    /*
    a {
        padding: rem(5px) rem(10px);
        border: 1px solid color('red');
        border-radius: 12px;

        @include link-states() {
            color: white;
            background-color: color('red');
        }
    }*/
}

// Toggle
//
.c-nav-toggle {
    // Medium Max
    @include media-max(md) {
        display: none;
    }

    // Medium Desktops
    @include media(md) {
        display: none;
        //position: relative;
        //width: 32px;
        //height: 32px;
        //padding: 0;
        //background-color: transparent;
        //border: 0;
        //appearance: none;
        //outline: none;
    }
}

// Toggle Text
//
.c-nav-toggle__text {
    position: absolute;
    top: 50%;
    left: neg(52px);
    font-size: rem(16px);
    font-weight: 600;
    color: color('red');
    text-transform: uppercase;
    transform: translateY(neg(50%));
}

// Toggle Line
//
.c-nav-toggle__line {
    display: block;
    position: relative;
    width: 100%;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background-color: color('red');
    transition-timing-function: cubic-bezier(.55, .055, .675, .19);
    transition-duration: 0.32s;

    .is-visible & {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background-color: inherit;
    }

    &:before {
      top: neg(8px);
      transition: top 0.2s ease-in .25s, opacity .1s ease-in;

      .is-visible & {
        top: 0;
        opacity: 0;
        transition: top 0.2s ease-out, opacity .1s ease-out .12s;
      }
    }

    &:after {
      bottom: neg(8px);
      transition: bottom 0.2s ease-in .25s, transform 0.32s cubic-bezier(.55, .055, .675, .19);

      .is-visible & {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.2s ease-out, transform 0.32s cubic-bezier(.215, .61, .355, 1) .12s;
      }
    }
}
