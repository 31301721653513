///
// Team Members
///
.c-team-members {
    display: flex;
    flex-wrap: wrap;
    margin-left: neg(halve($base-gutter));
    margin-right: neg(halve($base-gutter));
}

///
// Team Member
///
.c-team-member {
    width: 50%;
    margin-top: rem($base-gutter);
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));

    // Landscape
    @media (min-width: 568px) and (max-width: 736px) and (orientation: landscape) {
        width: 33.3333333333%;
    }

    // Small Tablets
    @include media(sm) {
        width: 33.3333333333%;
    }

    // Medium Desktops
    @include media(md) {
        width: 25%;
    }
}

///
// Image
///
.c-team-member__image {
    margin-bottom: rem(10px);
}

///
// Name
///
.c-team-member__name {
    margin-bottom: 0;
}

///
// Title
///
.c-team-member__title {
    display: block;
    margin-bottom: 0;
    font-size: rem(14px);

    .c-team-member__bio & {
        margin-bottom: rem(halve($base-gutter));
    }
}

///
// Bio
///
.c-team-member__bio {
    display: none;
    width: 100%;
    max-width: 960px;
    padding: rem($base-gutter);
    font-family: $proxima-nova;

    // Medium Desktops
    @include media(md) {
        padding: rem(double($base-gutter));
    }
}
