h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: rem(20px);
    font-weight: 500;
    color: color('red');
    line-height: 1.25;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

h1 {
    @include font-size('h1');
}

h2 {
    @include font-size('h2');
}

h3 {
    @include font-size('h3');
}

h4 {
    @include font-size('h4');
}

h5 {
    @include font-size('h5');
}

h6 {
    @include font-size('h6');
    font-weight: 300;
}

p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem(20px);
    letter-spacing: 1px;
}

p:not(:last-child) {
    margin-bottom: rem($base-gutter);
}

strong {
    font-weight: 700;
}

a {
    color: color('red');
    text-decoration: none;

    @include link-states() {
        color: $base-font-color;
    }
}

li:not([class]) {
    font-size: rem(16px);
    letter-spacing: 1px;
}
