body.archive,
body.blog,
body.single-post, {
  .c-section--main {
    margin-top: 0px;
  }
}

.c-post {

  .c-post__columns {
    display: flex;

    @include media-max(md) {
      flex-wrap: wrap;
    }
  }

  .c-post__image {
    width: 25%;
  }

  .c-post__summary {
    width: 75%;
    margin-left: 30px;

    @include media-max(md) {
      margin-left: 0px;
      margin-top: 30px;
    }
  }

  .c-post__summary,
  .c-post__image {
    @include media-max(md) {
      width: 100%;
    }
  }
}

.c-post + .c-post {
  padding-top: 45px;
  margin-top: 45px;
  border-top: 1px solid #f0eded;
}

.c-post__categories {
  margin-bottom: 30px;

  h4 {
    text-align: center;
  }

  .row--categories {
    display: flex;
    justify-content: center;
  }

  .c-category {
    display: inline-block;
  }

  .c-category + .c-category {
    ::before {
      content: '-';
      margin: 0 5px;
    }
  }
}

.c-content__header--blog {
  text-align: center;
  border-bottom: 1px solid #f0eded;
  padding-bottom: 3.75rem;
  margin-bottom: 30px;

  @include media-max(md) {
    padding-bottom: 1.875rem;
  }
}
