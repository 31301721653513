body {
    margin: 0;
    font-size: $base-font-size;
    font-family: $proxima-nova;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(120px); // Height of the header
    }

    // Large Desktops
    @include media(lg) {
        padding-top: rem(135px); // Height of the header
    }

    &.nav-is-open {
        overflow: hidden;
    }
}

blockquote {
    margin: 0;
}

hr {
    width: 100%;
    height: 1px;
    margin-top: rem($base-gutter);
    margin-bottom: rem($base-gutter);
    background-color: color('red');
    border: 0;

    // Medium Desktops
    @include media(md) {
        margin-top: rem(double($base-gutter));
        margin-bottom: rem(double($base-gutter));
    }
}

hr.clear {
    background-color: transparent;
}

ol:not([class]),
ul:not([class]) {
    margin-top: 0;
    margin-bottom: rem($base-gutter);
}

ul:not([class]) {
    padding-left: rem($base-gutter);
    list-style-type: square;

    li:not([class]) {
        + li:not([class]) {
            margin-top: rem(halve($base-gutter));
        }
    }
}
