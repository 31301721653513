///
// Center
///
.u-text-center {
    text-align: center;
}

///
// Lower
///
.u-text-lower {
    text-transform: lowercase;
}