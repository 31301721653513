///
// Section
///
.c-section {
    padding-top: rem($base-gutter);
    padding-bottom: rem($base-gutter);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(double($base-gutter));
        padding-bottom: rem(double($base-gutter));
    }
}