///
// Footer
///
.c-footer {
    padding-top: rem($base-gutter);
    padding-bottom: rem($base-gutter);
    color: white;
    background-color: color('grey', 'darkest');

    // Medium <=
    @include media-max(md) {
        text-align: center;
    }

    // Medium Desktops
    @include media(md) {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
    }

    a {
        color: inherit;

        @include link-states() {
            color: color('red');
        }
    }

    p {
        font-size: rem(18px);
    }

    hr {
        background-color: #343030;
    }
}

///
// Bird
///
.c-footer__bird {
    position: absolute;
    top: neg(350px);
    right: 0;
    z-index: z('behind');
    width: 340px;
    height: 378px;

    // Medium Desktops
    @include media(md) {
        top: neg(680px);
        width: 414px;
        height: 414px;
    }

    // Investment Approach
    .page-id-14 & {
        display: none;
    }
}

///
// Copyright
///
.c-footer__copyright {
    font-size: rem(12px) !important;
    text-transform: uppercase;
}

///
// Row
///
.c-footer__row {
    // Medium Desktops
    @include media(md) {
        display: flex;
        justify-content: space-between;
    }
}
