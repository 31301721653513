///
// Item
///
.c-portfolio-item {
    + .c-portfolio-item {
        margin-top: rem(halve($base-gutter));
        padding-top: rem(halve($base-gutter));
        border-top: 1px solid color('grey');
    }
}

///
// Name, Address, Footage & Total
///
.c-portfolio-item__name,
.c-portfolio-item__address,
.c-portfolio-item__footage,
.c-portfolio-group__total {
    display: block;
    font-size: rem(18px);
}

///
// Name
///
.c-portfolio-item__name {
    a {
        color: inherit;
    }
}

///
// Square Footage
///
.c-portfolio-item__footage {
    text-align: right;
}

///
// Total
///
.c-portfolio-group__total {
    margin-top: rem($base-gutter);

    // Small Landscape
    @include media(xs) {
        text-align: right;
    }
}