///
/// Returns the number as a negative.
///
/// @param {number} $number
///
@function neg($number) {
    @return -($number);
}

///
/// Returns number as rounded half the number passed.
///
/// @param {number} $number
///
@function halve($number) {
    @return round($number / 2);
}

///
/// Returns number as rounded quarter the number passed.
///
/// @param {number} $number
///
@function quarter($number) {
    @return round($number / 4);
}

///
/// Returns number as rounded double the number passed.
///
/// @param {number} $number
///
@function double($number) {
    @return round($number * 2);
}