///
// Hero
///
.c-hero {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    //max-height: 468px;
    color: white;
    text-align: center;
    background-color: color('red');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    // Medium <=
    @include media-max(md) {
        min-height: 268px;
    }

    // Medium Desktops
    @include media(md) {
        min-height: 618px;
        padding-top: 100px;
        padding-bottom: 100px;
        background-position: center top;
    }

    .page-template-template-home & {
        height: 100vh;
        min-height: 100vh;
    }

    .o-container {
        position: relative;
    }
}

///
// Content
///
.c-hero__content {
    width: 100%;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;

    p {
        font-size: rem(24px);
        font-weight: 600;
        line-height: 1.8;
        letter-spacing: 3px;
    }

    cite {
        font-size: rem(18px);
        font-weight: 600;
        font-style: normal;
        letter-spacing: 2px;
    }
}

///
// Action
///
.c-hero__action {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    width: 50px;
    height: 27px;
    margin-left: auto;
    margin-right: auto;
    background-image: url('../../dist/images/icon-down-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
        width: auto;
        font-weight: 600;
        color: white;
        text-transform: uppercase;
        background-image: none;

        &:before {
            content: 'Scroll Down';
        }
    }
}
