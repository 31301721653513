.c-header {
    background-color: white;

    // Medium Desktops
    @include media(md) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: z('demigod');
        width: 100%;

        .is-scrolling & {
            background-color: rgba(white, 0.95);
        }

        .logged-in & {
            top: 32px;
        }
    }
}

///
// Mobile
///
.c-header__mobile {
    height: 50px;
    border-bottom: 1px solid color('grey', 'light');

    // Medium Desktops
    @include media(md) {
        display: none;
    }
}

///
// Top
///
.c-header__top {
    padding-top: rem(halve($base-gutter));
    padding-bottom: rem(halve($base-gutter));
    border-bottom: 1px solid color('grey', 'light');

    // Medium Desktops
    @include media(md) {
        padding-top: rem(20px);
        padding-bottom: rem(20px);
    }

    .o-container {
        // Medium Desktops
        @include media(md) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

///
// Bottom
///
.c-header__bottom {
    // Medium Desktops
    @include media(md) {
        padding-top: rem(20px);
        padding-bottom: rem(20px);
    }
}

///
// Phone
///
.c-header__phone {
    /*
    display: block;
    position: absolute;
    top: 8px;
    left: 15px;
    width: 32px;
    height: 32px;
    background-image: url('../../dist/images/phone.png');
    background-position: center;
    background-repeat: no-repeat;
    */

    // Medium <=
    @include media-max(md) {
        display: inline-block;
        position: relative;
        margin-top: rem($base-gutter);
        padding-left: rem(40px);
        font-weight: 600;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 23px;
            height: 23px;
            background-image: url('../../dist/images/phone.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .c-header__mobile & {
            display: none;
        }
    }

    // 1024
    @include media(md) {
        .c-nav--primary & {
            display: none;
        }
    }
}
