///
// Case Study
///
.c-case-study {
    margin-bottom: rem($base-gutter);
}

///
// Image
///
.c-case-study__image {
    // Medium Max Desktops
    @include media-max(md) {
        margin-bottom: rem($base-gutter);
    }
}